import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const view = name => () => import("@/views/" + name)

const routes = [
  {
    path: "/",
    name: "main",
    component: view("main")
  },
  {
    path: "/platform",
    name: "platform",
    component: view("platform")
  },
  {
    path: "/product/:name",
    name: "product",
    component: view("product")
  },
  {
    path: "/solution/:name",
    name: "solution",
    component: view("solution")
  },
  {
    path: "/services/:name",
    name: "services",
    component: view("services")
  },
  {
    path: "/resource/:name",
    name: "resource",
    component: view("resource")
  },
  {
    path: "/SecOps/:name",
    name: "SecOps",
    component: view("SecOps")
  },
  // {
  //   path: "/aboutus/:name",
  //   name: "aboutus",
  //   component: view("aboutus")
  // },
  {
    path: "/aboutus",
    name: "aboutus",
    component: view("aboutus")
  },
  {
    path: "/intelligence",
    name: "intelligence",
    component: view("intelligence")
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
