<template>
  <div id="app">
    <!-- <scale-box :width="1920" bgc="transparent" :delay="100"> -->
    <router-view />
    <!-- </scale-box> -->

  </div>
</template>
<script>
// import ScaleBox from "vue2-scale-box";
// import scale from './components/sacle.vue';
export default ({
  provide() { reload: this.reload },
  data() {
    return {
    }
  },
  method: {
  },
  components: {
    // ScaleBox
  },
  mounted() {
    // var browerWidth = window.innerWidth; //浏览器可视宽度
    // console.log(browerWidth)
    // var baseWidth = 1920; //设计稿宽度
    // var zoomValue = browerWidth / baseWidth; //缩放比例计算
    var zoomValue = 1440 / 1920
    // if(browerWidth<1920){
    document.getElementById("app").style.zoom = zoomValue * 100 + '%'  //为了导航可以固定
    //   return
    // }
    // window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
    //   browerWidth = window.innerWidth;
    //   zoomValue = browerWidth / baseWidth;
    // var zoomValue = 1440 / 1920

    // document.getElementById("app").style.zoom = zoomValue * 100 + '%'  //为了导航可以固定
    // }
  }
})
</script>
<style lang="less">
#app {
  // width: 1920px;
  // max-width: 1920px;
  font-family: "微软雅黑", Arial, sans-serif;
  // position: absolute;
  // transform: scale(var(--scale)) translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0
}

.news_right::-webkit-scrollbar {
  // display: block !important;
}

.news_right::-webkit-scrollbar {
  width: 5px !important;
  height: 0;
}

/* 滚动槽 */
.news_right::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
}

/* 滚动条滑块 */
.news_right::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) !important;
}

.news_right::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.el-menu--horizontal>.el-submenu .el-submenu__title {
  font-size: 22px !important;
  border-bottom: 0 !important;
}

.el-menu--horizontal>.el-menu-item {
  font-size: 22px !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  font-size: 22px !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
  border-bottom: 0 !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:active {
  border-bottom: 0 !important;
}

.el-submenu__title i {
  color: #fff !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: transparent !important;
}

.el-menu {
  background-color: transparent !important;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}

/* 热点和荣誉轮播样式 */
/* 走马灯 */
.indicator .container .el-carousel {
  width: 100% !important;
}

/* 小圆点 */
.indicator .el-carousel__indicators {
  left: 0 !important;
  top: -5px;
}

.indicator .el-carousel__indicator--vertical .el-carousel__button {
  background-color: transparent !important;
  border: 1px solid #000 !important;
  width: 7px !important;
  height: 7px !important;
  border-radius: 50% !important;
}

.indicator .el-carousel__indicators--outside button {
  background-color: transparent !important;
  border: 1px solid #000 !important;
  width: 7px !important;
  height: 7px !important;
  border-radius: 50% !important;
}

.indicator .el-carousel__indicator.is-active button {
  background-color: #b50000 !important;
  border: 1px solid #b50000 !important;
  width: 7px !important;
  height: 7px !important;
  border-radius: 50% !important;
}

.indicator .el-carousel__indicators--vertical {
  top: 46% !important;
  width: 8px !important;
}

.indicator .el-carousel__indicator--vertical {
  padding: 8px 12px !important;
}

/* banner轮播样式 */
/* 走马灯 */
.banner_lunbo .container .el-carousel {
  width: 100% !important;
}

/* 小圆点 */
.banner_lunbo .el-carousel__indicators {
  bottom: 45px !important;
}

.banner_lunbo .el-carousel__button {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.banner_lunbo .el-carousel__indicators--outside button {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.banner_lunbo .el-carousel__indicator.is-active button {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.banner_content .el-carousel__indicator--horizontal {
  padding: 10px 16px !important;
}

.pieApply .el-input__inner {
  width: 330px !important;
  height: 40px !important;
  background: #F4F4F4 !important;
  border: none !important;
}

.pieApply .el-form-item:nth-child(1),
.pieApply .el-form-item:nth-child(2) {
  width: 156px !important;
}

.pieApply .el-form-item:nth-child(1) .el-input__inner {
  width: 156px !important;
}

.pieApply .el-form-item:nth-child(2) .el-input__inner {
  width: 156px !important;
}

.pieApply .el-form-item__label {
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #262626;
  line-height: 30px;
}

.pieApply .el-form-item:nth-child(2) .el-form-item__label {
  // margin-left: 10px;
}

.pieApply .el-checkbox__label {
  width: 320px;
  // height: 48px;
  font-size: 12px;
  // font-family: FZLTHJW;
  font-weight: normal;
  color: #999999;
  line-height: 18px;

  display: inline-grid;
  white-space: pre-line;
  word-wrap: break-word;
}

.pieApply .el-form-item.is-error .el-input__inner,
.pieApply .el-form-item.is-error .el-input__inner:focus,
.pieApply .el-form-item.is-error .el-textarea__inner,
.pieApply .el-form-item.is-error .el-textarea__inner:focus,
.pieApply .el-message-box__input input.invalid,
.pieApply .el-message-box__input input.invalid:focus {

  background: rgba(231, 22, 22, 0.08) !important;
  border: 1px solid #E71616 !important;
}

.pieApply .el-form-item__error {

  margin-top: -66px !important;
  margin-left: 67px !important;
}

.pieApply .el-form-item:nth-child(1) .el-form-item__error,
.pieApply .el-form-item:nth-child(2) .el-form-item__error {

  margin-top: -66px !important;
  margin-left: 24px !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #999999 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #E71616 !important;
  border-color: #E71616 !important;
}

.search .el-input__inner {

  width: 642px !important;
  height: 58px !important;
  border: 1px solid #DA2A2A !important;
  line-height: 58px !important;
  border-radius: 0 !important;
}

.search .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 642px !important;
}

.search .el-input__icon {
  line-height: 58px !important;
  width: 22px !important;
  height: 20px !important;
  font-size: 20px !important;
  color: #E71616 !important;
}

.tiaojian .el-radio-button__inner {
  border: 0 !important;
  height: 44px !important;
  line-height: 44px !important;
  font-size: 20px !important;
  color: #666666 !important;
  padding: 0 !important;
  margin-right: 60px !important;
  background-color: transparent !important;
}

.tiaojian .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: transparent !important;
  color: #DA2A2A !important;
  box-shadow: none !important;
}

.el-pagination__editor.el-input .el-input__inner {
  // width: 28px !important;
}

.block .el-color-picker__icon,
.block .el-input,
.block .el-textarea {
  width: 28px !important;
}

// 信赖小圆点样式
/* 小圆点 */
.xinlai .el-carousel__indicator--horizontal .el-carousel__button {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.xinlai .el-carousel__indicator.is-active button {
  background-color: #b50000 !important;
  border: 1px solid #b50000 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.xinlai .el-carousel__indicator--horizontal {
  padding: 10px 10px !important;
}

// 荣誉小圆点样式
/* 小圆点 */
.rongyu .el-carousel__indicator--horizontal .el-carousel__button {
  background-color: transparent !important;
  border: 1px solid #000 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.rongyu .el-carousel__indicator.is-active button {
  background-color: #b50000 !important;
  border: 1px solid #b50000 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
}

.rongyu .el-carousel__indicator--horizontal {
  padding: 10px 10px !important;
}
</style>
<style>
@import '../static/iconfont-weapp-icon.css';
</style>
