import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './assets/icon/iconfont.css'
import './assets/icon2/iconfont.css'
import './assets/iconCaise/iconfont-weapp/iconfont-weapp-icon.css'
import './assets/iconCaise2/iconfont-weapp/iconfont-weapp-icon.css'
// 引入初始css样式 npm i animate.css
import "./assets/css/animate.css"
import "animate.css";

import './assets/icons'
//百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'L3rGiAelFGih1ZC6y5aKj01exsOzhcgC'
})
var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?d23ca9359bfdd8f41ab5b66c9f8abeda";    //正式
  // hm.src = "https://hm.baidu.com/hm.js?b84582820998663239987879d8fea374";  //测试
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

let observer = new IntersectionObserver(well => {
  well.forEach(res => {
    const className = ` animate__animated animation-iteration-count=1 ${res.target.attributes.animatename.value}`
    if (res.isIntersecting && res.target.className.indexOf('animate__animated') < 0) {
      res.target.className += className
      setTimeout(_ => {
        // res.target.className = res.target.className.replace(className, '')
      }, 1000)
    }
  })
}, {});

Vue.directive('observer', {
  inserted(el, binding) {
    el.setAttribute('animateName', binding.arg ? binding.arg : binding.value)
    observer.observe(el)
  }
})


Vue.config.productionTip = false

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
router.beforeEach((to, from, next) => {
  if (_hmt) {
      if (to.path) {
          _hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
